<template>
  <div class="product">
    <img :src="image" alt="" class="product__image" />
    <h3 class="product__title">{{ product.title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'Product',
  props: ['product'],
  computed: {
    image() {
      return require(`@/assets/products/productsImage/${this.product.image}`);
    }
  }
};
</script>

<style></style>
