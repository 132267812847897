<template>
  <div class="products route__general">
    <h2 class="route__title">Ürünler</h2>
    <div class="products__images">
      <Product
        v-for="product in products"
        :key="product.id"
        :product="product"
        @click.native="changeRoute(product.id)"
      />
    </div>
  </div>
</template>

<script>
import Product from '../Product.vue';
import productsCategoryData from '../../assets/products/productsCategoryData.json';
export default {
  name: 'Products',
  components: {
    Product
  },
  data() {
    return {
      products: productsCategoryData
    };
  },
  methods: {
    changeRoute(id) {
      this.$router.push(`/urunler/${id}`);
    }
  },
  metaInfo: {
    title: 'Ürünler | Emka Radyatör',
    meta: [
      {
        name: 'description',
        content:
          'Her türlü iş makinası jeneratör, radyatör, klima, yağ soğutucu, intercooler hava kompresörü; imalat, bakım ve tamiri'
      },
      {
        name: 'keywords',
        content: 'radyatör, makina sanayi, makine, emka radyatör,'
      },
      { name: 'robots', content: 'index,follow' }
    ]
  }
};
</script>

<style></style>
